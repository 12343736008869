import MUITable from 'components/ui/table-chart/MUITable';
import { TBasePaths } from 'constants/translations';
import { useTranslation } from 'next-i18next';
import { memo, useMemo } from 'react';
import colors from 'styles/colors';
import { isNotNull } from 'utils/arrayHelper';

import { useHomeDashboardContext } from '../../HomeDashboardContext';

const LeasesExpirations = () => {
  const { t } = useTranslation();

  const {
    expirationFilters,
    setExpirationFilters,
    leaseExpirationCountsData: data,
  } = useHomeDashboardContext();

  const expirationData = useMemo(
    () =>
      [
        data.expired > 0
          ? {
              id: 'expired',
              label: 'leaseWidgetExpired',
              color: colors.errorMain,
              value: data.expired,
            }
          : null,
        data.monthly > 0
          ? {
              id: 'monthly',
              label: 'leaseWidgetMonthly',
              color: colors.cloudGrey,
              value: data.monthly,
            }
          : null,
        data.zeroMonths > 0
          ? {
              id: 'zeroMonths',
              label: 'leaseWidgetZeroMonths',
              color: colors.errorMain,
              value: data.zeroMonths,
            }
          : null,
        {
          id: 'oneYearExpiration',
          label: 'leaseWidgetExpires1To12Months',
          color: colors.ruddyPink,
          value: data.oneYearExpiration,
        },
        {
          id: 'twoYearsExpiration',
          label: 'leaseWidgetExpires13To24Months',
          color: colors.orangePeel,
          value: data.twoYearsExpiration,
        },
        {
          id: 'threeYearsExpiration',
          label: 'leaseWidgetExpires25To36Months',
          color: colors.saffronMango,
          value: data.threeYearsExpiration,
        },
        {
          id: 'fourAndFiveYearsExpiration',
          label: 'leaseWidgetExpires4To5Years',
          color: colors.babyBlue,
          value: data.fourAndFiveYearsExpiration,
        },
        {
          id: 'overSixYearsExpiration',
          label: 'leaseWidgetExpires6OrMoreYears',
          color: colors.azure,
          value: data.overSixYearsExpiration,
        },
        data.ownedLeases > 0
          ? {
              id: 'ownedLeases',
              label: 'owned',
              color: colors.astronautBlue,
              value: data.ownedLeases,
            }
          : null,
        data.noExpiration > 0
          ? {
              id: 'noExpiration',
              label: 'leaseWidgetNoExpiration',
              color: colors.errorMain,
              value: data.noExpiration,
            }
          : null,
      ].filter(isNotNull),
    [data]
  );
  const expirationLabels = expirationData.map(({ label }) =>
    t(`${TBasePaths.PA_COMMON_WORD}.${label}`)
  );
  const expirationCategoryKeys = useMemo(
    () => expirationData.map(({ label }) => label),
    [expirationData]
  );
  const expirationValues = useMemo(
    () =>
      expirationData.map(({ value }) => {
        return { COUNT: value };
      }),
    [expirationData]
  );
  const expirationIds = expirationData.map(({ id }) => id);

  const handleClickTableRow = (categoryKey?: string) => {
    if (categoryKey) {
      let filters: string[] = [];
      if (expirationFilters.includes(categoryKey)) {
        filters = expirationFilters.filter((filter) => filter !== categoryKey);
      } else {
        filters = [categoryKey];
      }

      setExpirationFilters(filters);
    }
  };

  return (
    <MUITable
      data={expirationValues}
      firstColumnCategoryKeys={expirationCategoryKeys}
      firstColumnIds={expirationIds}
      firstColumnLabels={expirationLabels}
      barFillColor={undefined}
      labelsFormatter={['numberWithCommas']}
      suffix={null}
      textWrapCategoriesColumnCell="nowrap"
      sx={{
        '& .MuiTableRow-root': {
          cursor: 'pointer',
        },
      }}
      onClickRow={handleClickTableRow}
      isRowSelected={(key: string) => expirationFilters.includes(key)}
    />
  );
};

export default memo(LeasesExpirations);
