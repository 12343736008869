import { GridStyled } from 'components/pages/Home/components/HomeDashboard/HomeDashboard.css';
import HomeDashboardGraph from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraph';
import HomeDashboardList from 'components/pages/Home/components/HomeDashboardList/HomeDashboardList';
import { switchLanguage } from 'components/routing/utils/languageUtils';
import { PageWrapper } from 'components/ui/page-wrapper';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { NextPage } from 'next';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import colors from 'styles/colors';

import { HomeDashboardProvider } from '../../HomeDashboardContext';

const HomeDashboardPage: NextPage = () => {
  const preferences = useUserPreferences();

  // Fail-safe to get the user-info data in case data was flushed.
  const [user] = useRecoilState(userModelState);

  useEffect(() => {
    switchLanguage(user?.selectedUserClient?.userLanguage);
  }, [user?.selectedUserClient?.userLanguage]);

  return (
    <HomeDashboardProvider>
      <PageWrapper padding="0" backgroundColor={colors.white}>
        <GridStyled
          container
          spacing={0}
          direction={StackDirectionEnum.ROW}
          alignContent="flex-start"
          alignItems="stretch"
        >
          <GridStyled
            item
            sm={8}
            md={9}
            container
            direction={StackDirectionEnum.COLUMN}
            alignContent="flex-start"
            alignItems="stretch"
          >
            {/* SUB-HEADER, MIDDLE & RIGHT PANEL */}
            <HomeDashboardGraph preferences={preferences} />
          </GridStyled>
          <GridStyled
            item
            sm={4}
            md={3}
            container
            direction={StackDirectionEnum.COLUMN}
            alignContent="flex-start"
            alignItems="stretch"
          >
            {/* RIGHT PANEL */}
            <HomeDashboardList preferences={preferences} />
          </GridStyled>
        </GridStyled>
      </PageWrapper>
    </HomeDashboardProvider>
  );
};

export default HomeDashboardPage;
