import { DASHBOARD_GRAPH_TOGGLES } from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraphFilterDropDown';
import MUITable from 'components/ui/table-chart/MUITable';
import { useMemo } from 'react';
import colors from 'styles/colors';
import { FormatterType } from 'utils/formatters';

import { GraphData, GraphDataItem } from '../../../../../types/dashboard-metric';

const getValueByType = (item: GraphDataItem, key: string) => {
  const responses = [item.totalCostTaxed, item.rentArea, item.costPerAreaTaxed];
  const index = DASHBOARD_GRAPH_TOGGLES.indexOf(key);
  return responses[Math.max(0, index)] ?? 0;
};

const getFormatByType = (key: string) => {
  const responses = [FormatterType.INTEGER, FormatterType.INTEGER, FormatterType.FLOAT_TWO_DECIMAL];
  const index = DASHBOARD_GRAPH_TOGGLES.indexOf(key);
  return responses[Math.max(0, index)];
};

interface LeasesMetricsProps {
  typeValue: string;
  data: GraphData | undefined;
}

const LeasesMetrics = ({ typeValue, data }: LeasesMetricsProps) => {
  const metricData = useMemo(() => {
    const metrics: { label: string; value: number }[] = [];

    if (data?.metrics) {
      Object.values(data.metrics).forEach((item) => {
        if (item.id) {
          const value = getValueByType(item, typeValue);
          metrics.push({ label: item.id, value });
        }
      });
    }

    return metrics.sort((a, b) => b.value - a.value);
  }, [data?.metrics, typeValue]);

  const metricLabels = useMemo(() => metricData.map((item) => item.label), [metricData]);
  const metricValues = useMemo(
    () =>
      metricData.map((item) => ({
        COUNT: item.value,
      })),
    [metricData]
  );

  return (
    <MUITable
      data={metricValues}
      firstColumnLabels={metricLabels}
      barFillColor={colors.amethystMain}
      labelsFormatter={[getFormatByType(typeValue)]}
      suffix=""
    />
  );
};

export default LeasesMetrics;
