import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { ChipComponent, ChipComponentProps } from 'components/ui/chip/ChipComponent/ChipComponent';
import { ChipColor } from 'enums/ChipColorEnum';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';
import { TBasePaths } from 'constants/translations';

enum CostScheduleChipEnum {
  ACTUAL = 'actual',
  BUDGET = 'budget',
  ESTIMATE = 'estimate',
}

function checkCostScheduleLabel(label: string): label is CostScheduleChipEnum {
  const isKnownCostScheduleLabel = Object.values<string>(CostScheduleChipEnum).includes(label);
  return isKnownCostScheduleLabel;
}

const costScheduleChipIconMap: Record<CostScheduleChipEnum, React.JSX.Element> = {
  [CostScheduleChipEnum.ACTUAL]: <OfflinePinIcon fontSize="small" />,
  [CostScheduleChipEnum.BUDGET]: <MonetizationOnOutlinedIcon fontSize="small" />,
  [CostScheduleChipEnum.ESTIMATE]: <DriveFileRenameOutlineOutlinedIcon fontSize="small" />,
};

const costScheduleChipColorMap: Record<CostScheduleChipEnum, ChipColor> = {
  [CostScheduleChipEnum.ACTUAL]: ChipColor.Periwinkle,
  [CostScheduleChipEnum.BUDGET]: ChipColor.Mint,
  [CostScheduleChipEnum.ESTIMATE]: ChipColor.Warning,
};

function CostScheduleChipComponent({ label, chipSize, minWidth, maxWidth }: ChipComponentProps) {
  const { t } = useTranslation();

  const isKnownCostScheduleLabel = checkCostScheduleLabel(label);

  if (isKnownCostScheduleLabel) {
    const costScheduleIcon = costScheduleChipIconMap[label];
    const costScheduleColor = costScheduleChipColorMap[label];
    return (
      <ChipComponent
        label={t(`${TBasePaths.PA_ENUM}.costSchedStatus.${label}`)}
        icon={costScheduleIcon}
        chipSize={chipSize}
        minWidth={minWidth}
        maxWidth={maxWidth}
        chipColor={costScheduleColor}
      />
    );
  }

  return (
    <ChipComponent
      label={t(`${TBasePaths.PA_ENUM}.costSchedStatus.${label}`)}
      chipSize={chipSize}
      minWidth={minWidth}
      maxWidth={maxWidth}
      chipColor={ChipColor.Default}
    />
  );
}

export const CostScheduleChip = memo(CostScheduleChipComponent);
