import { CardActions, CardContent, styled, Typography } from '@mui/material';
import colors from 'styles/colors';
import { withDefaultProps } from 'styles/common';

export const CardContentStyled = styled(CardContent)({
  '&.MuiCardContent-root': {
    padding: 0,
  },
});

export const CardActionStyled = styled(CardActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.8rem 0 0 0',
}));

export const LeaseIdText = styled(withDefaultProps(Typography, { variant: 'styledBold' }))(() => ({
  color: colors.midnightLight,
}));

export const LeaseNameText = styled(withDefaultProps(Typography, { variant: 'styledBold' }))(
  () => ({
    color: colors.midnightLight,
    marginTop: '0.2rem',
  })
);

export const LeasePropertyLocationText = styled(
  withDefaultProps(Typography, { variant: 'styledBold' })
)(() => ({
  marginBottom: '1.2rem',
}));
