import { Box, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { ToggleButton } from './ToggleButton.css';

interface IProps extends ToggleButtonGroupProps {
  buttonList: IToggleButton[];
  onChange: (e: React.MouseEvent<HTMLElement>, newValue: string) => void;
  defaultValue?: string;
}

const StartIconContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '0.7rem',
}));

export interface IToggleButton {
  name: string | React.JSX.Element;
  value: string;
  startIcons?: {
    commonIcon: React.JSX.Element;
    activeIcon: React.JSX.Element;
  };
}

export const ToggleButtons = ({ onChange, buttonList, defaultValue, value, ...args }: IProps) => {
  const [currentValue, setCurrentValue] = useState<null | string>(defaultValue || null);
  const onChangeHandler = (e: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (!newValue) return;
    setCurrentValue(newValue);
    onChange(e, newValue);
  };

  useEffect(() => setCurrentValue(defaultValue ?? value), [value]);

  return (
    <ToggleButtonGroup value={currentValue} exclusive onChange={onChangeHandler} {...args}>
      {buttonList.map(({ value: buttonValue, name, startIcons }, i) => {
        return (
          <ToggleButton key={i} value={buttonValue} aria-label={buttonValue}>
            {startIcons && (
              <StartIconContainer>
                {buttonValue === currentValue ? startIcons.activeIcon : startIcons.commonIcon}
              </StartIconContainer>
            )}

            <Typography variant={'button'}>{name}</Typography>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
