import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ChipComponent, ChipComponentProps } from 'components/ui/chip/ChipComponent/ChipComponent';
import { ChipColor } from 'enums/ChipColorEnum';
import { PropertyStatusEnum } from 'enums/PropertyStatusEnum';
import { TBasePaths } from 'constants/translations';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

function checkLeaseLabel(label: string): label is PropertyStatusEnum {
  const isKnownLeaseLabel = Object.values<string>(PropertyStatusEnum).includes(label);
  return isKnownLeaseLabel;
}

const leaseChipIconMap: Record<PropertyStatusEnum, React.JSX.Element> = {
  [PropertyStatusEnum.ACTIVE]: <CheckCircleIcon fontSize="small" />,
  [PropertyStatusEnum.INACTIVE]: <RemoveCircleIcon fontSize="small" />,
  [PropertyStatusEnum.PROPOSED]: <AccessTimeFilledIcon fontSize="small" />,
  [PropertyStatusEnum.COMMITTED]: <FlagCircleIcon fontSize="small" />,
};

const leaseChipColorMap: Record<PropertyStatusEnum, ChipColor> = {
  [PropertyStatusEnum.ACTIVE]: ChipColor.Success,
  [PropertyStatusEnum.INACTIVE]: ChipColor.Error,
  [PropertyStatusEnum.PROPOSED]: ChipColor.Default,
  [PropertyStatusEnum.COMMITTED]: ChipColor.Warning,
};

function LeaseChipComponent({ label, chipSize, minWidth, maxWidth }: ChipComponentProps) {
  const { t } = useTranslation();

  const isKnownLeaseLabel = checkLeaseLabel(label);

  if (isKnownLeaseLabel) {
    const leaseIcon = leaseChipIconMap[label];
    const leaseColor = leaseChipColorMap[label];
    return (
      <ChipComponent
        label={t(`${TBasePaths.PA_ENUM}.leaseStatus.${label}`)}
        icon={leaseIcon}
        chipSize={chipSize}
        minWidth={minWidth}
        maxWidth={maxWidth}
        chipColor={leaseColor}
      />
    );
  }

  return (
    <ChipComponent
      label={t(`${TBasePaths.PA_ENUM}.leaseStatus.${label}`)}
      chipSize={chipSize}
      minWidth={minWidth}
      maxWidth={maxWidth}
      chipColor={ChipColor.Default}
    />
  );
}

export const LeaseChip = memo(LeaseChipComponent);
