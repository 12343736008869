import { Grid, Typography } from '@mui/material';
import {
  DashboardLeaseList,
  HomeLeasesCardContainer,
  HomeLeasesCardList,
  HomeLeasesHeader,
  StyledBoxInInfiniteScroll,
} from 'components/pages/Home/components/HomeDashboardList/HomeDashboardList.css';
import HomeDashboardListCard from 'components/pages/Home/components/HomeDashboardListCard/HomeDashboardListCard';
import { HomeDashboardListSkeletonLoader } from 'components/pages/Home/components/HomeDashboardListSkeletonLoader/HomeDashboardListSkeletonLoader';
import { AyInfiniteScroll } from 'components/shared/InfiniteScroll';
import { TBasePaths } from 'constants/translations';
import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import colors from 'styles/colors';

import { useHomeDashboardContext } from '../../HomeDashboardContext';
import { Preferences } from '../../types/Preferences';

interface HomeDashboardListProps {
  onSelect?: (lease: LeaseEntity) => void;
  preferences: Preferences;
}

const HomeDashboardList = ({ onSelect, preferences }: HomeDashboardListProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { filteredLeases, loadingFinished } = useHomeDashboardContext();

  const onClickCard = (lease: LeaseEntity) => {
    if (onSelect) {
      onSelect(lease);
    } else {
      router.push(`/leases/${lease.leaseUuid}`);
    }
  };

  return (
    <DashboardLeaseList variant="outlined" square>
      <HomeLeasesCardContainer container spacing={0}>
        <HomeLeasesHeader container>
          <Grid item>
            {/* @ts-ignore */}
            <Typography variant="styledVacantBox" color={colors.midnightLight}>
              {t(`${TBasePaths.PA_COMMON_WORD}.leases`).toUpperCase()}
            </Typography>
          </Grid>
          <Grid item></Grid>
        </HomeLeasesHeader>
        <HomeLeasesCardList container spacing={0}>
          {loadingFinished ? (
            <AyInfiniteScroll
              padding={'1rem'}
              dataLength={filteredLeases.length ?? 0}
              hasMore={false}
              next={() => {}}
              endMessage={
                <StyledBoxInInfiniteScroll>
                  <Typography variant="regularParagraph">
                    {t(`${TBasePaths.PA_COMMON_MESSAGES}.noMoreItems`)}
                  </Typography>
                </StyledBoxInInfiniteScroll>
              }
            >
              {filteredLeases.map((lease) => (
                <HomeDashboardListCard
                  key={lease.leaseUuid}
                  lease={lease}
                  preferences={preferences}
                  onClick={() => onClickCard(lease)}
                />
              ))}
            </AyInfiniteScroll>
          ) : (
            <HomeDashboardListSkeletonLoader rows={4} />
          )}
        </HomeLeasesCardList>
      </HomeLeasesCardContainer>
    </DashboardLeaseList>
  );
};

export default HomeDashboardList;
