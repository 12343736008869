import { SxProps } from '@mui/system';
import { DetailRowContainer, RowTitle, RowValue } from 'components/shared/DetailRow/DetailRow.css';

interface DetailRowProps {
  label: string;
  value: string | number | null | React.ReactNode | undefined;
  labelVariant?: string;
  valueVariant?: string;
  valueColor?: string;
  labelSx?: SxProps;
  valueSx?: SxProps;
  containerSx?: SxProps;
}

const DetailRow = ({
  label,
  value,
  labelVariant = 'subheadingSmall',
  valueVariant = 'widgetTitle',
  valueColor = 'secondary.main',
  labelSx,
  valueSx,
  containerSx,
}: DetailRowProps) => {
  return (
    <DetailRowContainer sx={containerSx}>
      {/* @ts-ignore */}
      <RowTitle variant={labelVariant} sx={labelSx}>
        {label.toUpperCase()}
      </RowTitle>
      {/* @ts-ignore */}
      <RowValue variant={valueVariant} color={valueColor} sx={valueSx}>
        {value === 0 ? 0 : value || '-'}
      </RowValue>
    </DetailRowContainer>
  );
};

export default DetailRow;
