import { Grid, Paper, styled, Typography } from '@mui/material';
import colors from 'styles/colors';
import { withDefaultProps } from 'styles/common';
import { HOME_METRIC_HEADER } from 'utils/layoutHelper';

export const HomeDashboardGraphFilterContainer = styled(Grid)(() => ({
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center',
}));

export const LeaseExpirationText = styled(
  withDefaultProps(Typography, { variant: 'styledVacantBox' })
)(() => ({
  color: colors.midnightLight,
  paddingBottom: '1.6rem',
}));

export const MetricTitleText = styled(withDefaultProps(Typography, { variant: 'styledVacantBox' }))(
  () => ({
    color: colors.midnightLight,
  })
);
export const RightPanel = styled(Grid)`
  border-top: 0.1rem solid ${colors.borderColor};
  border-left: 0.1rem solid ${colors.borderColor};
  position: relative;
  height: calc(100% - ${HOME_METRIC_HEADER});
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
`;

export const LeftPanel = styled(Grid)`
  border-top: 0.1rem solid ${colors.borderColor};
  padding: 1.5rem;
  position: relative;
`;

export const HomeDashboardGraphWrapper = styled(Paper)(() => ({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
}));
