import { Skeleton, Stack, styled } from '@mui/material';
import { withDefaultProps } from 'styles/common';

export const ListSkeletonLoader = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  padding: '2.4rem 3.2rem',
}));

export const CardSkeletonStack = styled(Stack)(() => ({
  width: '100%',
}));

export const CardSkeletonTop = styled(withDefaultProps(Skeleton, { variant: 'rectangular' }))(
  () => ({
    width: '100%',
    height: '12rem !important',
  })
);

export const CardSkeletonBottom = styled(withDefaultProps(Skeleton, { variant: 'rectangular' }))(
  () => ({
    width: '100%',
    height: '4rem !important',
    marginTop: '0.1rem',
    marginBottom: '2.4rem',
  })
);
