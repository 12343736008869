import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { TShortcuts } from 'constants/translations';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import moment from 'moment';

import logger from './logger';

export const monthDiffByDay = (startDate: Date, endDate: Date): number => {
  //calculates month difference using # of days and rounding up to nearest whole month. Typically used for # of months in a term
  return Math.ceil(moment.utc(endDate).diff(moment.utc(startDate), 'months', true));
};

export const monthDiffByMonth = (startDate: Date, endDate: Date): number => {
  //calculates month difference only using month index. Typically used for "months remaining"
  return (
    endDate.getUTCMonth() -
    startDate.getUTCMonth() +
    12 * (endDate.getUTCFullYear() - startDate.getUTCFullYear())
  );
};

export const getRemainingMonths = (date: Date | string | null) => {
  if (!date) return;
  return monthDiffByMonth(new Date(), new Date(date));
};

export const isDateBeforeToday = (dateToCheck: Date): boolean => {
  const today: Date = new Date();
  const dateToCheckTimestamp = dateToCheck.getTime();
  const todayTimestamp = today.getTime();
  return todayTimestamp > dateToCheckTimestamp;
};

export const addMonths = (date: Date, monthsToAdd: number): Date => {
  return moment.utc(date).add(monthsToAdd, 'months').toDate();
};

export const isFirstDayOfMonth = (dateString: string) => {
  const momentDate = moment.utc(dateString);
  return momentDate.date() === 1;
};

export const isLastDayOfMonth = (dateString: string) => {
  const momentDate = moment.utc(dateString);
  const endOfMonth = momentDate.clone().endOf('month');
  return endOfMonth.isSame(momentDate, 'day');
};

export const isStartOfMonth = (date: Date) => {
  return moment(date).startOf('day').isSame(moment(date).startOf('month').startOf('day'));
};

export const isEndOfMonth = (date: Date) => {
  return moment(date).endOf('day').isSame(moment(date).endOf('month').endOf('day'));
};

/**
 * Validates if the given string is a valid date string.
 *
 * @param {string} dateString - The date string to be validated.
 * @returns {boolean} Returns true if the string is a valid date, false otherwise.
 */
export const isDateString = (dateString: string): boolean => {
  try {
    const dateIso8601 =
      /^(\d{4})-(0[1-9]|1[0-2])-([0-2]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)Z$/;
    const isDateIso8601Valid = dateIso8601.test(dateString);
    const datetimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    const isDatetimeValid = datetimeRegex.test(dateString);
    if (isDateIso8601Valid || isDatetimeValid) {
      return true;
    }
    if (!isDateIso8601Valid || !isDatetimeValid) {
      return false;
    }
    const dateStringParsed = Date.parse(dateString);
    const isDateValid = !isNaN(dateStringParsed);

    if (isDateValid) {
      return true;
    }
    return isDatetimeValid;
  } catch {
    logger('isDateString: catch error, unable to determin if datetime is valid case');
    return true;
  }
};

export const formatDateWithDashes = <T>(value: T, t: (string: string) => string) => {
  if (value instanceof Date) {
    try {
      return value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    } catch {
      Snackbar.error(t(TShortcuts.DATE_NOT_VALID));
    }
  }
  return value;
};

export const formatInTimeZone = (date: Date, fmt: string, tz: string) => {
  const zonedDate = toZonedTime(date, tz);
  return format(zonedDate, fmt);
};
