import { Stack, styled, Typography } from '@mui/material';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import colors from 'styles/colors';
import { withDefaultProps } from 'styles/common';

export const HeaderCellContainer = styled(Stack)(() => ({
  padding: '0.8rem 1.6rem',
}));

export const HeaderCellValue = styled(
  withDefaultProps(Typography, {
    variant: 'headerStackedCellValue',
    whiteSpace: 'initial',
    align: 'center',
  })
)(() => ({
  color: colors.black,
  paddingBottom: '0.5rem',
}));

export const HeaderCellLabel = styled(
  withDefaultProps(Typography, {
    variant: 'headerStackedCellLabel',
    whiteSpace: 'nowrap',
    align: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  })
)(() => ({
  color: colors.midnightLight,
}));

export const TotalHeaderContainer = styled(
  withDefaultProps(Stack, { direction: StackDirectionEnum.ROW })
)`
  position: sticky;
  top: 0;
  background: ${colors.white};
  width: 100%;
  z-index: 100;
`;
