import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ChipComponent, ChipComponentProps } from 'components/ui/chip/ChipComponent/ChipComponent';
import { ChipColor } from 'enums/ChipColorEnum';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';
import { TBasePaths } from 'constants/translations';

enum PropertiesChipEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  FUTURE = 'future',
  PROPOSED = 'proposed',
}

function checkPropertyLabel(label: string): label is PropertiesChipEnum {
  const isKnownPropertyLabel = Object.values<string>(PropertiesChipEnum).includes(label);
  return isKnownPropertyLabel;
}

const propertyChipIconMap: Record<PropertiesChipEnum, React.JSX.Element> = {
  [PropertiesChipEnum.ACTIVE]: <CheckCircleIcon fontSize="small" />,
  [PropertiesChipEnum.INACTIVE]: <RemoveCircleIcon fontSize="small" />,
  [PropertiesChipEnum.FUTURE]: <AccessTimeFilledIcon fontSize="small" />,
  [PropertiesChipEnum.PROPOSED]: <FlagCircleIcon fontSize="small" />,
};

const propertyChipColorMap: Record<PropertiesChipEnum, ChipColor> = {
  [PropertiesChipEnum.ACTIVE]: ChipColor.Success,
  [PropertiesChipEnum.INACTIVE]: ChipColor.Error,
  [PropertiesChipEnum.FUTURE]: ChipColor.Default,
  [PropertiesChipEnum.PROPOSED]: ChipColor.Warning,
};

function PropertyChipComponent({ label, chipSize, minWidth, maxWidth }: ChipComponentProps) {
  const { t } = useTranslation();

  const isKnownPropertyLabel = checkPropertyLabel(label);

  if (isKnownPropertyLabel) {
    const propertyIcon = propertyChipIconMap[label];
    const propertyColor = propertyChipColorMap[label];
    return (
      <ChipComponent
        label={t(`${TBasePaths.PA_ENUM}.propertyStatus.${label}`)}
        icon={propertyIcon}
        chipSize={chipSize}
        minWidth={minWidth}
        maxWidth={maxWidth}
        chipColor={propertyColor}
      />
    );
  }

  return (
    <ChipComponent
      label={t(`${TBasePaths.PA_ENUM}.propertyStatus.${label}`)}
      chipSize={chipSize}
      minWidth={minWidth}
      maxWidth={maxWidth}
      chipColor={ChipColor.Default}
    />
  );
}

export const PropertyChip = memo(PropertyChipComponent);
