import { Stack, Typography } from '@mui/material';
import {
  HomeLeasesCard,
  LeaseCardContent,
  LeaseCardDivider,
} from 'components/pages/Home/components/HomeDashboardList/HomeDashboardList.css';
import {
  CardActionStyled,
  CardContentStyled,
  LeaseIdText,
  LeaseNameText,
  LeasePropertyLocationText,
} from 'components/pages/Home/components/HomeDashboardListCard/HomeDashboardListCard.css';
import { useAppEnums } from 'components/providers/AppEnumsProvider';
import DetailRow from 'components/shared/DetailRow';
import { LeaseChip } from 'components/ui/chip';
import { TBasePaths } from 'constants/translations';
import { LeaseEntity } from 'entityTypes/Lease/Lease';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import { useTranslation } from 'next-i18next';
import { formatExpirationDate } from 'utils/leaseHelper';
import { calculateTotalRentAreaByLease } from 'utils/leaseSpaceHelper';
import {
  convertSqUnitsToPreferred,
  formatAmountBasedOnLocale,
  formatDateBasedOnPreferences,
} from 'utils/unitConverters';

import { Preferences } from '../../types/Preferences';

const concatIfNotNull = (value: string | null | undefined, suffix: string = '') => {
  if (!value) return '';
  return value + suffix;
};

interface HomeDashboardListCardProps {
  lease: LeaseEntity;
  preferences: Preferences;
  onClick: (lease: LeaseEntity) => void;
}

const HomeDashboardListCard = ({ lease, onClick, preferences }: HomeDashboardListCardProps) => {
  const { t } = useTranslation();
  const { UnitMeasEnum } = useAppEnums();

  const totalRentAreaByLease = calculateTotalRentAreaByLease(lease, preferences.uom);

  const units =
    totalRentAreaByLease &&
    convertSqUnitsToPreferred(preferences.uom, lease?.unitMeas, totalRentAreaByLease, UnitMeasEnum);
  const unitsFormatted = formatAmountBasedOnLocale(units, 0, preferences.formatNumber);

  const translatedUOM = t(`${TBasePaths.PA_ENUM}.unitMeas.${preferences.uom}`);
  const rentArea = totalRentAreaByLease
    ? `${unitsFormatted} ${translatedUOM}`
    : t(`${TBasePaths.PA_COMMON_MESSAGES}.notSpecified`);

  const leaseExpireDate = lease.expireDate;

  return (
    <HomeLeasesCard key={lease.leaseUuid} onClick={() => onClick(lease)}>
      <LeaseCardContent>
        <CardContentStyled>
          <Stack direction={StackDirectionEnum.COLUMN}>
            <div>
              {/* @ts-ignore */}
              <Typography variant="styledBold">{lease.leaseName}</Typography>
              <LeaseIdText>{lease.leaseId ? ` (${lease.leaseId})` : ''}</LeaseIdText>
            </div>
            {/* @ts-ignore */}
            <LeasePropertyLocationText>
              {concatIfNotNull(lease?.property?.city, ', ') +
                concatIfNotNull(lease?.property?.stateProvince, ' ') +
                (lease?.property?.country?.countryName ?? '')}
            </LeasePropertyLocationText>

            <DetailRow
              label={t(`${TBasePaths.PA_COMMON_WORD}.rentableArea`) + ':'}
              value={rentArea}
              labelVariant="styledBold"
              valueVariant="styledBold"
            />
            <DetailRow
              label={t(`${TBasePaths.AVANT_COMMON_WORD}.expiration`) + ':'}
              value={formatExpirationDate(
                preferences,
                leaseExpireDate,
                lease.isMonthToMonth,
                '-',
                true
              )}
              labelVariant="styledBold"
              valueVariant="styledBold"
            />
            {lease.expireReviewDate && (
              <DetailRow
                label={t(`${TBasePaths.PA_COMMON_WORD}.leaseReview`) + ':'}
                value={formatDateBasedOnPreferences(preferences, lease.expireReviewDate)}
                labelVariant="styledBold"
                valueVariant="styledBold"
              />
            )}
          </Stack>
          <LeaseCardDivider />
        </CardContentStyled>
        <CardActionStyled>
          {/* @ts-ignore */}
          <LeaseNameText>{lease.leaseType?.leaseTypeName}</LeaseNameText>
          <LeaseChip label={lease.leaseStatus ?? ''} />
        </CardActionStyled>
      </LeaseCardContent>
    </HomeLeasesCard>
  );
};

export default HomeDashboardListCard;
